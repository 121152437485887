import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class BoatWatercraftInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Boat & Watercraft Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "Whether you're just out on the water for the day or enjoying a weekend fishing trip, it's important to protect you and your boat or watercraft from the risks. Contact us for a watercraft insurance quote!" }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} alt="Boat on the water"/>
        <Callout
          title="Boat & Watercraft Insurance"
          content="More than 75 million Americans boat recreationally every year. Whether you're just out on the water for the day or you're enjoying a weekend fishing trip, it's important to protect you and your boat or watercraft from the risks."
        />
        <div className="site-body page-content">
          <h2>What Boat & Watercraft Insurance Usually Covers</h2>
          <p>
          <ul>
          <li><strong>Collision.</strong> This will pay for the repair or replacement of your boat if it hits or is hit by an object.</li>
          <li><strong>Comprehensive.</strong> This covers incidents of vandalism, theft or damage to involving a collision.</li>
          <li><strong>Property Damage.</strong> If you damage someone else's boat, dock, property or structures this will pay for the damages.</li>
          <li><strong>Bodily Injury.</strong> If someone is injured while using the boat this will pay for medical bills, lost income, pain, suffering and legal expenses.</li>
          <li><strong>Additional Coverages.</strong> On many policies you can add coverage for fishing equipment, oil spills, personal property and roadside assistance, as well as damages and injuries from accidents caused by boaters who are uninsured or underinsured.</li>
        </ul>
          </p>
          <DidYouKnow
            fact="Generally, boat insurance ranges anywhere from as little as $75 to as much as $500 per year."
            source="Trusted Choice"
          />
          <h2>What Boat Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li><strong>Wear & Tear.</strong> Damage to the inside or outside of the boat, including blown engines, resulting from wear & tear are not covered.</li>
          <li><strong>Hurricane Damage.</strong> Many policies do not cover damage to your boat resulting from a storm. However, this can be included in some policies.</li>
          <li><strong>Out of Water.</strong> If your boat is damaged while towing it or at your home, this will be covered under your auto or homeowners insurance policy.</li>
          <li><strong>Certain Waters.</strong> Make sure your coverage includes the certain geographic areas you wish to navigate. Most policies have geographic restrictions.</li>
        </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="a Boat Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default BoatWatercraftInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "boat-watercraft-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-22.jpg" }) {
      ...mainBg
    }
  }
`;
